import React from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import AOS from 'aos';
import researchDocBenchmark from "./documents/Advice report.pdf";
import benchmarkImage from "./photos/adviesverslag.png";
import 'aos/dist/aos.css'; 

function Conclusie() {

    AOS.init();

    return (
        <React.StrictMode>
        <Navbar />
        <div class="bodyContent">
        <div style={{ backgroundImage: `url(${benchmarkImage})`}} class="coverImage benchmarkImg noTranslate conclusie" id="projects">
                    <div class="project lessOpacity">
                        <div class="projectTitleDesc">
                            <h2 class="projectTitle">Conclusie van de design challenge</h2>
                                <em class="subtitle">Conclusie</em>
                                <br />
                                <a href={researchDocBenchmark} style={{display: "inline-block"}}>
                                <input type="button" value="Adviesverslag" class="viewBtn" />
                                </a>
                        </div>
                        <div class="projectDesc">
                            
                        </div>
                    </div>
                </div>
                <div class="explanation">
                <div data-aos="fade-up">
                    <h1 class="researchTitle" id="research">Resultaten</h1>
                    <h2 class="researchSubtitle" id="concurrenten">Voltooing van de design challenge</h2>
                    <div data-aos="fade-up">
                    <p class="researchText"><b>Waarom? -</b><img src={benchmarkImage} alt="Forcasts current editor"  className="sidePicture"/> Aan het begin van dit project is een design challenge opgesteld. Dankzij het onderzoek en het werk van dit project, is deze 
                    design challenge voltooid. Toch is het slim om heel concreet het antwoord op de design challenge te documenteren. Dit is gedaan, zodat het antwoord niet gezocht moet worden 
                    tussen alle onderzoeksverslagen. De design challenge gaat als volgt: <b>Ontwerp een uitbreiding van Forcast, die ervoor zorgt dat gebruikers van Forcast, wanneer ze iets willen aanpassen in Forcast, de vrijheid hebben om de aanpassingen te maken die ze willen, zodat gebruikers van Forcast minder snel wensen naar nieuwe uitbreidingen van Forcast.</b>
                    <br /><br /><b>Hoe? -</b> Door middel van alle producten en onderzoeken van dit project is tot de resultaten gekomen.
                    <br /><br />
                    <b>Resultaten -</b> Dit project heeft geleid tot alle producten die te zien zijn in dit portfolio. De conclusies van al deze producten vormen samen de resultaten van 
                    de gehele design challenge. In het projectplan is het duidelijk geworden dat gebruikers veel wensen hebben voor Forcast. Echter is hier geen tijd voor gevonden om echt in deze 
                    wensen te duiken. Daarom is besloten om meer in deze wensen te duiken met een benchmarkonderzoek en een onderzoek naar de gebruikers. Hieruit is gebleken dat de editor het grootste 
                    probleem is. Er is niet genoeg designvrijheid in de editor, waardoor de gebruikersvriendelijkheid van Forcast omlaag gaat. <br /><br />
                    Hiervoor is een oplossing ontworpen en deze oplossing is getest door experts. De oplossing is een uitbreiding op de bestaande editor. Gebruikers kunnen nu gebruik maken van sjablonen en 
                    deze ook zelf maken. Animaties, stickers en GIFs zijn ook toegevoegd. Daarnaast kan bij de gemaakte sjablonen per element aangegeven worden welke acties 
                    een gebruiker mag uitvoeren op dit element. Ook is het mogelijk om geheel een eigen slide te designen met de Forcast editor. Er is ook nog gekeken naar een samenwerking met Canva, 
                    door middel van een API-koppeling. Dit is niet mogelijk doordat deze nog niet is uitgegeven voor commerciële projecten.<br /><br />
                    Op de lange termijn moet Forcast ook onderhouden blijven. Daarvoor is een adviesverslag gemaakt. In dit verslag wordt beschreven dat Encore voornamelijk meer 
                    moet testen met gebruikers. Daarnaast zal Encore in de toekomst veel winst halen uit het in de gaten houden van concurrenten. Ook moet er goed gekeken worden naar 
                    het animatiemenu en het menu voor elementpermissies. Verder kan Encore stickerpacks toevoegen tegen een kleine betaling. Als laatste is het aangeraden dat Encore de 
                    enquête nogmaals verstuurt, nadat de nieuwe versie van Forcast een maand live is.
                    <br /><br />
                    <b>Conclusie -</b> Een nieuwe, uitgebreidere editor was dus de oplossing voor het probleem van Forcast. Het grootste deel van de wensen ging namelijk over het tekort designvrijheid. 
                    Door middel van de sjablonen, animaties, stickers, GIFs en de mogelijkheden om zelf sjablonen te maken, had de gebruiker alle vrijheid die deze wilde hebben. Gebruikers moeten nu geen 
                    externe designprogramma's gebruiken om slides te ontwerpen. Dankzij de nieuwe tooltips was het makkelijker voor gebruikers om opnieuw te leren hoe Forcast werkt, als ze het al een tijd niet 
                    meer hadden gebruikt. Tijdens de tests was door collega's geverifiëerd dat deze oplossing het probleem echt aanpakt en dat Forcast hiermee kon concurreren met andere narrowcastingsystemen. En door 
                    de toekomstadviezen was Encore voorbereid op de toekomst van de doorontwikkeling van Forcast. Door meer te testen met gebruikers en door concurrenten goed in de gaten te blijven houden, zou 
                    het probleem van Forcast zich niet kunnen herhalen. De design challenge is dus voltooid.  
                    </p>
                    <br />
                    </div>
                    </div>
                </div>
                </div>
                <Footer />
        </React.StrictMode>
    )
}

export default Conclusie;