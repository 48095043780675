import React from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import AOS from 'aos';
import researchDocBenchmark from "./documents/Advice report.pdf";
import benchmarkImage from "./photos/adviesverslagBeter.png";
import adviespresentatie from "./documents/Adviespresentatie Forcast.pdf";
import 'aos/dist/aos.css'; 

function Adviesverslag() {

    AOS.init();

    const rgb = 0;
    const a = 0.30;

    return (
        <React.StrictMode>
        <Navbar />
        <div class="bodyContent">
        <div style={{ backgroundImage: `url(${benchmarkImage})`}} class="coverImage benchmarkImg noTranslate adviesverslag" id="projects">
                    <div class="project">
                        <div class="projectTitleDesc">
                            <h2 class="projectTitle">Adviesverslag</h2>
                                <em class="subtitle">Toekomstadvies Forcast</em>
                                <br />
                                <a href={researchDocBenchmark} style={{display: "inline-block"}}>
                                <input type="button" value="Adviesverslag" class="viewBtn" />
                                </a>
                        </div>
                        <div class="projectDesc">
                            
                        </div>
                    </div>
                </div>
                <div class="explanation">
                <div data-aos="fade-up">
                    <h1 class="researchTitle" id="research">Resultaten</h1>
                    <h2 class="researchSubtitle" id="concurrenten">Adviesverslag en -presentatie</h2>
                    <div data-aos="fade-up">
                    <p class="researchText"><b>Waarom? -</b><img src={benchmarkImage} alt="Forcasts current editor"  className="sidePicture"/> Het adviesverslag is geschreven als toekomstbeeld voor de doorontwikkeling van Forcast. In dit verslag wordt omschreven welke stappen Encore in de toekomst 
                    kan nemen om ervoor te zorgen dat gebruikers tevreden blijven met Forcast. Het adviesverslag zal dan ook het overdrachtsdocument zijn na afloop van deze stage. Daarnaast is een adviespresentatie ontwikkeld, 
                    waar de adviezen concreet is visueel in staan. Dit zorgt ervoor dat Encore niet alleen een lang document moet lezen als ze de adviezen willen weten.
                    <br /><br /><b>Hoe? -</b> Door middel van alle resultaten uit de voorgaande onderzoeken, is het toekomstadvies voor Forcast opgezet kunnen worden.
                    <br /><br />
                    <b>Resultaten -</b> Het eerste advies is dat Encore nieuwe sjablonen kan verkrijgen door een nieuwe werknemer aan te nemen of een bestaande werknemer hiervoor tijd te geven. Daarnaast kunnen gebruikers ook sjablonen aanmaken die in de bilbiotheek van Encore kunnen komen. 
                    Deze sjablonen moeten eerst goedkeuring krijgen van Encore, om te voorkomen dat er onprofessionele ontwerpen in de biblitotheek verschijnen. Als tweede advies moet goed gekeken worden naar animaties en elementpermissies in Forcast. Deze dingen moeten op een 
                    duidelijke manier worden weergegeven aan de gebruiker. De configuratie van beide functies zal via een popup moeten, gezien een popup genoeg ruimte heeft voor alle configuratieopties. Ten derde kan Encore gebruik maken van stickerpacks tegen betaling. Niet alle stickerpacks 
                    zullen dan tegen betaling zijn, maar slechts enkele stickerpacks voor bijvoorbeeld evenementen als Carnaval. <br /><br />
                    Daarnaast zijn de laatste adviezen dat Encore meer samen gaat werken met gebruikers, de enquête opnieuw gaat sturen en dat Encore concurrenten goed in de gaten blijft houden. Door meer met gebruikers samen te werken en ze meer te betrekken in het proces, kunnen problemen 
                    veel sneller worden opgespoord. Ook kan hierdoor een oplossing worden gemaakt die precies aansluit bij wat de gebruikers willen. Met het opnieuw sturen van de enquête kan gekeken worden of de nieuwe editor de wensen van de gebruikers aanpakt. Door de enquête een maand na de 
                    release van de doorontwikkeling opnieuw te sturen, kan de werking van de oplossing goed geanalyseerd worden. Als laatste moet Encore goed de concurrenten in de gaten houden, gezien meteen gehandeld moet worden op interessante ideeën van benchmarks. Als Encore te lang wacht met 
                    de benchmarks evenaren, dan gaan gebruikers weer wensen naar deze functies. Het is dus van belang dat benchmarks goed in de gaten worden gehouden, om niet achter te lopen op concurrenten.
                    <br /><br />
                    <b>Conclusie -</b> Het belangrijkste advies was dat Encore meer samen moet gaan werken met gebruikers van Forcast. Op deze manier zou Encore problemen van gebruikers 
                    sneller kunnen opsporen. Hier kon Encore dan meteen op handelen, om te voorkomen dat de wensen weer zouden oplopen. Ditzelfde gold ook voor het in de gaten houden van concurrenten. 
                    De adviezen over de verwerving van nieuwe sjablonen werden gecombineerd tot één advies. De gebruikers zouden sjablonen kunnen maken, die Encore keurt voor hun bibliotheek. En wanneer de toestroom 
                    van sjablonen van gebruikers weinig sjablonen levert, dan kan een (nieuwe) werknemer deze gaten opvullen. Dit waren de belangrijkste adviezen om de doorontwikkeling van Forcast op de juiste 
                    manier door te zetten.   
                    </p>
                    <p>
                    <p class="researchText">Hieronder is het volledige adviesverslag te lezen. Daarnaast is de adviespresentatie hieronder ook te vinden.</p>
                    <a href={researchDocBenchmark} className="linkBtn">
                        <input type="button" value="Adviesverslag" class="viewBtn"/>
                    </a>
                    <a href={adviespresentatie} className="linkBtn" style={{marginLeft: "20px"}}>
                        <input type="button" value="Adviespresentatie" class="viewBtn"/>
                    </a>
                    </p>
                    </div>
                    </div>
                </div>
                </div>
                <Footer />
        </React.StrictMode>
    )
}

export default Adviesverslag;