import React from "react";
import Navbar from "./components/navbar";
import { Link } from "react-router-dom";
import Footer from "./components/footer";
import AOS from 'aos';
import { HashLink } from "react-router-hash-link";
import forcast from "./photos/forcast.png";
import 'aos/dist/aos.css'; 
import diepDiagram from './photos/diep_process_diagram.png';
import prototypeV2 from "./photos/prototype v2.png";
import readingGuide from "./documents/Reading guide S8.pdf";
import designPatternSearch from "./photos/Design pattern search.png";

function ReadingGuide() {

    AOS.init();

      const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    // const scrollWithOffset2 = (el) => {
    //     const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    //     const yOffset = -160; 
    //     window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    // }

    return (
        <React.StrictMode>
        <Navbar />
        <div class="bodyContent">
        <div class="coverImage readingGuide noTranslate" id="projects">
                    <div class="project">
                    <div class="projectDesc">
                        </div>
                        <div class="projectTitleDesc">
                            <h2 class="projectTitle">Uitbreiding van een bestaand narrowcastingsysteem</h2>
                                <em class="subtitle">Afstudeerproject</em>
                                <br />
                                <a href={readingGuide}>
                                <input type="button" value="Reading Guide Afstudeerproject" class="viewBtn" style={{display: "inline-block"}}/>
                                </a>
                        </div>
                    </div>
                </div>
                <div class="explanation">
                <div data-aos="fade-up">
                    <h1 class="researchTitle">Reading Guide</h1>
                    <h2 className="researchSubtitle" style={{cursor:"auto"}}><em style={{color:"black"}}>Afstudeerproject - Uitbreiding van een bestaand narrowcastingsysteem</em></h2>
                    <h2 className="researchSubtitle" style={{cursor:"auto"}}>Inhoud</h2>
                    <p className="researchText">
                        <p className="contentsText">Op deze pagina:</p>
                    <ul>
                        <HashLink scroll={el => scrollWithOffset(el)} to="/reading-guide#assignment" className="contentsLink">
                            <li>De opdracht</li>
                        </HashLink>
                        <HashLink scroll={el => scrollWithOffset(el)} to="/reading-guidet#description" className="contentsLink">
                            <li>Beschrijving van het proces en de resultaten</li>
                        </HashLink>
                        <HashLink scroll={el => scrollWithOffset(el)} to="/reading-guide#projectPlan" className="contentsLink">
                            <li>Vergelijking projectplan en de realiteit</li>
                        </HashLink>
                        <HashLink scroll={el => scrollWithOffset(el)} to="/reading-guide#explanation" className="contentsLink">
                            <li>Uitleg van resultaten</li>
                        </HashLink>
                        <HashLink scroll={el => scrollWithOffset(el)} to="/reading-guide#reflection" className="contentsLink">
                            <li>Reflectie van de opdracht</li>
                        </HashLink>
                        <HashLink scroll={el => scrollWithOffset(el)} to="/reading-guide#proof" className="contentsLink">
                            <li>Bewijs van leeruitkomsten</li>
                        </HashLink>
                        <HashLink scroll={el => scrollWithOffset(el)} to="/reading-guide#references" className="contentsLink">
                            <li>Bronnen</li>
                        </HashLink>
                    </ul>

                    <h2 className="researchSubtitle" style={{cursor:"auto"}} id="assignment">De opdracht</h2>

                    <br />

                    <img src={forcast} alt="Forcasts current editor"  className="sidePicture"/>Encore heeft een narrowcastingsysteem genaamd Forcast. Met Forcast kunnen gebruikers slides maken op basis van widgets en live-data. Deze slides kunnen zij dan streamen naar hun beeldschermen. Zo kunnen gebruikers de interne en externe communicatie verbeteren. Gebruikers kunnen bijvoorbeeld agenda-afspraken, verjaardagen, het weer en nieuwsberichten laten zien. Hiermee kan communicatie bevorderd worden. Gebruikers kunnen dus informatie laten zien die relevant is voor interne en externe communicatie.<br /><br />
                    Maar Forcast heeft een probleem. Klanten wensen regelmatig naar nieuwe functies of verbeteringen. Echter heeft Encore geen tijd om al deze functies en verbeteringen te verwerken. Daarnaast wensen klanten vaak naar kleine bugfixes of oplossingen voor persoonlijke irritaties. Encore wil daarom weten of een oplossing gevonden kan worden voor deze toestroom van wensen.
                    <br /><br />
                    Encore wil weten hoe Forcast het beste doorontwikkeld kan worden. Het doel hiervan is om een oplossing te vinden die de toekomstige toestroom van wensen zal verminderen. Voor deze oplossing is het belangrijk dat deze het grootste deel van de wensen aanpakt. Daarnaast moet de oplossing in de toekomst nog steeds relevant blijven. Als de oplossing niet future-proof is, dan zal hetzelfde probleem over een aantal jaar terugkomen. <br /><br />
                    De wensen van gebruikers spelen een grote rol in dit project. Het moet onderzocht worden of er een overkoepelend onderwerp is, waar een groot deel van de wensen in vallen. Ook spelen concurrenten en benchmarks een grote rol. Concurrenten hebben belangrijke functies die Forcast niet geïmplementeerd heeft. Deze functies kunnen gebruikt worden als richtlijnen en inspiratie.
                    <br /><br />
                    Wanneer duidelijk is wat de oplossing gaat worden, dan wordt deze ontworpen tot een prototype. Dit prototype wordt ook getest, om verificatie te krijgen dat het probleem wordt aangepakt. Daarnaast zullen de tests bevestigen of de user flow goed is voor de gebruikers. Met de feedback uit deze tests kan het prototype verder ontwikkeld worden en kan een adviesdocument geschreven worden. Op basis hiervan kan Encore de doorontwikkeling voortzetten na deze stageperiode.
                        

                    <br /><br />

                    <h2 className="researchSubtitle" style={{cursor:"auto"}} id="description">Beschrijving van het proces en de resultaten</h2>

                    <br />

                    <h2 className="researchSubtitle2" style={{cursor:"auto"}} id="projectPlan">Vergelijking van het projectplan en de realiteit</h2>

                    <br />

                    Het projectplan was het leidende document tijdens deze stage. Toch hadden een aantal afwijkingen van het projectplan plaatsgevonden. Deze afwijkingen waren het resultaat van het vinden van nieuwe, efficiëntere werkwijzen. Een andere reden waarom deze afwijkingen hadden plaatsgevonden, was omdat bepaalde onderdelen meer tijd hadden gekost dan gepland. <br /><br />
                    De eerste afwijking die had plaatsgevonden, was de hoe de enquête was aangepakt. In het projectplan stond dat de enquête naar alle gebruikers verstuurd zou worden. Dit wilde Encore niet. De reden hiervoor was om geen <em style={{color:"black", fontSize:"18px"}}>‘slapende honden wakker te maken’</em>. Gebruikers zouden anders nieuwe problemen kunnen opvallen. Encore had echter geen tijd om deze problemen op korte termijn op te lossen. Volgens Koen zouden hierdoor klanten geïrriteerd kunnen raken. Dit kon leiden tot gebruikers die naar concurrenten gingen kijken. Daarom had Koen een lijstje opgesteld met gebruikers die goed feedback zouden leveren. Deze gebruikers hadden de enquête ontvangen en drie van de vijf gebruikers hebben deze ingevuld.
                    <br /><br />
                    Daarnaast week de planning af van het projectplan. Dit kwam doordat sommige delen van het project langer of korter duurden dan gepland. Op twee dingen na was alles op de planning uitgevoerd. Als eerste waren er in plaats van schetsen wireframes gemaakt. Dit was gedaan, omdat wireframes gebruikt konden worden voor het testen van interacties. Het tweede punt dat was aangepast, was het schrappen van de Developmentfase voor de portfolio-deadline van 18 juni. Dit paste niet meer in de planning. Maar gezien de graduation convention op 9 juli zal zijn, gaat de Developmentfase plaatsvinden tussen 18 juni en 9 juli. Zo kan een interactieve proof of concept gepresenteerd worden tijdens de afstudeerzitting.
                    <br /><br />
                    Naast de afwijkingen van de planning en de aanpak van de enquête, was het project trouw gebleven aan het projectplan. De deelvragen van de design challenge hebben het onderzoek in de juiste richting gestuurd. Ook waren de categorieën van het DOT-framework allemaal gebruikt. Zonder het DOT-framework hadden de onderzoeksvragen niet beantwoord kunnen worden. Al met al was het grootste deel van het project in lijn met het projectplan. Op een paar afwijkingen na, had het projectplan goed geholpen met dit project. In de toekomst zal wel beter gekeken moeten worden naar de planning, omdat hier de meeste afwijkingen waren voorgekomen. Hierover zal meer staan in de reflectie.


                    <br /><br />

                    <h2 className="researchSubtitle2" style={{cursor:"auto"}} id="explanation">Uitleg van resultaten</h2>

                    <br />

                    Dit project bestond uit vier fases: Analyse, Design, Testen en Evaluatie. Al deze fases hadden bijbehorende deliverables. Deze deliverables samen vormen de resultaten van dit project. Aan het einde van deze stageperiode zullen deze deliverables overgedragen worden aan Encore, in een overdrachtsmeeting. Hier worden de resultaten beschreven en hoe deze resultaten het probleem oplossen.
                    <br /><br />
                    <b>Analyse</b><br /><br />
                    De Analysefase had geresulteerd in het projectplan, het onderzoek naar de wensen van klanten en het benchmarkonderzoek. Ten eerste werd het duidelijk dat gebruikers erg veel wensten van Forcast. Er waren zo veel wensen, dat Encore te weinig tijd had om deze wensen te vervullen. Maar door dit dieper te onderzoeken, bleek het dat veel wensen hetzelfde overkoepelende probleem aankaartten. De slide editor van Forcast had namelijk te weinig designvrijheid en functionaliteit. Dit werd bevestigd in het benchmarkonderzoek, waaruit bleek dat sjablonen en het zelf maken van slides de standaard was. Een uitgebreidere editor van dus van groot belang voor de gebruikers. <br /><br />
                    Hiermee werden de vragen beantwoord over wat gebruikers wensten van Forcast en wat concurrenten succesvol maakte. Een editor op basis van sjablonen, met alle functies om zelf sjablonen te kunnen maken, bleek het antwoord op deze vragen. Hiermee zouden gebruikers de designvrijheid krijgen die gewenst werd. Met deze oplossing op het oog, werd de nieuwe editor ontworpen.<br /><br />
                    <b>Design</b> <br /><br />
                    <img src={prototypeV2} alt="Forcasts new editor"  className="sidePicture"/>Op basis van de gevonden oplossing in de Analysefase, kon een interactief prototype gemaakt worden. Door middel van iteratie op basis van testresultaten (zie Testfase), kon het prototype het probleem steeds beter aanpakken. Dit prototype was het leidende ontwerp voor toekomstige doorontwikkeling van Forcast. Het was gemaakt om stakeholders en gebruikers een beeld te geven van de toekomst van Forcast. Met dit prototype werd het ook makkelijker om de oplossing te communiceren met stakeholders. Door middel van dit beeld, bleef minder aan interpretatie over.
                    <br /><br />Dit prototype loste het probleem op. Gebruikers hadden meer vrijheid in design, door middel van de sjablonen en de nieuwe ontwerptools. Ze konden geheel hun eigen slides en sjablonen maken of ze gebruik konden maken van bestaande sjablonen. Hiernaar wensten de gebruikers het meeste. Tevens zal deze oplossing in de toekomst nog steeds relevant blijven, waardoor de toestroom van wensen aangepakt zal blijven. Encore zal dan voornamelijk wensen blijven ontvangen over kleine bugfixes, waarvoor Encore makkelijker tijd kan vinden. <br /><br />
                    <b>Testen</b><br /><br />
                    Tijdens de Testfase was een testplan gemaakt om gestructureerd te kunnen testen. Met het testplan werd tevens de onderzoeksvraag beantwoord over hoe het meest effectief getest kon worden in dit project. Er zou met twee soorten testen getest gaan worden: A/B/X-testing en Usability Testing. Optioneel kon nog een Quality review uitgevoerd worden, mocht hier tijd voor zijn. Deze Quality review zou het doel hebben gehad om alle bugs op te sporen, voordat de oplossing live zou gaan. <br /><br />
                    Met het A/B/X-testing werd getest of de oplossing het probleem zou aanpakken. Tevens werd getest welke layout uit drie keuzes het meest gebruiksvriendelijk was. Tijdens het A/B/X-testen werd getest met een low-fidelity wireframe, gezien deze makkelijk aan te passen was. Uit deze tests was gebleken dat één layout unaniem het beste was. Deze layout was dus gebruikt om een high-fidelity prototype van te maken. Daarnaast bleek uit deze tests dat CMS-widgets maatwerk per klant zou worden. Deze functie moest vervangen worden met stickers en GIFs, voor meer designvrijheid.
                    <br /><br />Het high-fidelity prototype werd getest met Usability Testing. Hiermee werd de uitvoering van de oplossing getest en er werd gekeken naar de gebruiksvriendelijkheid. Uit deze tests was gebleken dat beter aangegeven moest worden welk element de gebruiker aanpast. Andere belangrijke resultaten waren previews van animaties, het maken van sjablonen zonder toezicht van Encore, tooltips en foutjes in consistentie die verwarring schepten. Verder bleek dat de testers potentie in de oplossing zagen. De testers verwachtten dat met deze oplossing de wensen van de klanten aangepakt zouden worden. Tevens werd er verwacht dat de gebruikersvriendelijkheid ook bevorderd zou worden, wanneer de feedback verwerkt zou worden. Al deze resultaten hadden daarnaast de onderzoeksvraag beantwoord hoe validatie gekregen kon worden op de oplossing.<br /><br />
                    <b>Evaluatie</b><br /><br />
                    In de Evaluatiefase was het projectproces nagelopen en hierop was gereflecteerd. Dit was gedaan om concreet te krijgen hoe een onderzoek als deze het meest efficiënt uitgevoerd kon worden. Tevens beantwoordde dit dezelfde onderzoeksvraag. Daarnaast was een adviesverslag gemaakt en was de design challenge voltooid. Uit de reflectie van het projectproces waren een aantal verbeterpunten gekomen. Ten eerste moest meer druk gezet worden achter de enquête. Hierdoor zou de enquête voorspoediger verlopen en zou er minder tijd zijn kwijtgeraakt tijdens de ontwikkeling hiervan. Als tweede verbeterpunt moest meer naar feedback gevraagd worden. Doordat dit te weinig werd gedaan op het begin van het project, liep de voortgang soms spaak. Als laatste moest meer getest worden met gebruikers. Nu waren gesimuleerde tests uitgevoerd, die niet in de mening van echte gebruikers resulteerden.
                    <br /><br />Het adviesverslag en -presentatie waren gemaakt om Encore een duidelijk toekomstbeeld te geven voor Forcast. Er is gekozen om presentatie erbij te maken, om een goede overdracht te kunnen geven. Enkel het document zal nooit echt gelezen worden. Hierin kwam één van de voornoemde verbeterpunten van het proces terug. Encore moest meer samen gaan werken met gebruikers voor tests, in projecten als deze. Door met gebruikers te testen en samen te werken, kon de oplossing nog meer afgesteld worden naar de wensen van de gebruikers. Verder is het belangrijk dat Encore de bibliotheek met sjablonen regelmatig blijft bijwerken. Hiervoor zou een huidige werknemer wekelijks tijd voor kunnen krijgen en gebruikers kunnen sjablonen opsturen, voor Encore om deze te keuren. Goedgekeurde sjablonen komen dan ook in de bibliotheek. Als laatste moeten concurrenten in de gaten worden gehouden. Met nieuwe ontwikkelingen zou Encore dan meteen kunnen handelen, zodat Forcast niet meer gaat achterlopen op de benchmarks.  
                    <br /><br />Met al deze resultaten kon vastgesteld worden dat met dit onderzoek en deze nieuwe editor de design challenge is voltooid. De design challenge luidde: <b>"Ontwerp een uitbreiding van Forcast, die ervoor zorgt dat gebruikers van Forcast, wanneer ze iets willen aanpassen in Forcast, de vrijheid hebben om de aanpassingen te maken die ze willen, zodat gebruikers van Forcast minder snel wensen naar nieuwe uitbreidingen van Forcast."</b> Het prototype, gebaseerd op het voorgenoemde onderzoek, pakt het probleem van designvrijheid aan bij gebruikers. Gebruikers zullen nu alles kunnen maken van zij willen in Forcast. Daarnaast kunnen ze ook sjablonen gebruiken. Dit was waar gebruikers al lang naar wensten. Met deze oplossing zijn al deze wensen aangepakt. Het probleem dat Encore te veel wensen kreeg, is hiermee opgelost.


                    <br /><br />

                    <h2 className="researchSubtitle2" style={{cursor:"auto"}} id="reflection">Reflectie van de opdracht</h2>
                    <em className="researchQuestion">Voor de reflectie is de DIEP-Methode gebruikt (Describe, Interpret, Evaluate, Plan) (Bond, 2023).</em>

                    <br /><br />

                    <img src={diepDiagram} alt="DIEP-evaluation diagram" className="sidePicture"/><b>Describe (Wat is er gebeurd? Wat heb je geleerd?)</b><br /><br />
                    Ik heb aan een project gewerkt wat de doorontwikkeling van een bestaand systeem omvat. De eerste paar weken van dit project had ik te weinig feedback gevraagd, waardoor opviel dat de voortgang langzamer ging. De echte trigger hiervoor was de Midterm Review, waar ik geconfronteerd werd met het feit dat ik achterliep en zo niet ging afstuderen. De tweede helft van het project was het feedback vragen door mij opgepakt en ik merkte meteen hoe de voortgang van het project voorspoediger liep. Doordat ik op het begin van het project te weinig naar feedback vroeg, is de planning flink aangepast. Doordat de voortgang hierdoor traag was, konden een aantal taken pas later uitgevoerd worden. Voor de planning moeten in de toekomst meer buffermomenten ingepland worden. Dit was niet het geval bij dit project, waardoor sommige taken geschrapt moesten worden. Als laatste moet in de toekomst meer druk gezet worden achter taken waarvoor ik afhankelijk ben van anderen. Een goed voorbeeld hiervan was de enquête. Hier had ik meer druk achter moeten zetten, zodat deze enquête veel sneller verstuurd had kunnen worden.
                    <br /><br />
                    <b>Interpret (Wat betekent deze leerervaring? Waarom?)</b><br /><br />
                    Deze leerervaring betekent veel voor mij, gezien deze verbeterpunten belangrijk zijn in het werkveld. Deze punten hebben mij laten zien dat het belangrijk is om in projecten het leiderschap niet uit het oog te verliezen. Het is van belang om statusupdates te blijven doen bij stakeholders en om regelmatig feedback te vragen. Omdat ik dit op het begin van het project niet had gedaan, verliep het begin chaotisch en wist ik soms niet precies wat ik 
                    moest doen. Vanaf het moment dat ik twee keer per week een vast feedbackgesprek had, liep het hele project veel soepeler. Dit project had mij echt laten inzien hoe belangrijk de samenwerking met stakeholders was en wat de gevolgen waren als stakeholders niet genoeg zijn ingelicht.
                    <br /><br />
                    <b>Evaluate (Hoe waardevol was de leerervaring?)</b><br /><br />
                    Een voordeel van het in zicht hebben van deze verbeterpunten is dat ik nu een beeld heb bij wanneer ik meer druk moet zetten achter taken. Voor dit project dacht ik dat ik irritant zou zijn door druk te zetten achter mijn project, terwijl mijn collega's het ook druk hadden. Maar in dit project was duidelijk gebleken dat druk gezet moest worden achter projectonderdelen die afhankelijk waren van andere mensen. Hiermee zullen toekomstige projecten voorspoediger lopen. De les die ik hieruit heb geleerd, is dat ik soms 'irritant' mag zijn, omdat dit mijn werk vooruit helpt. Anderen vragen mij ook vaker of ik ergens mee kan helpen, en dit ervaar ik meestal niet als irritant. Dus andere mensen zullen zo ook niet denken als ik een keer vraag om hulp bij een taak. Tevens geldt dit ook voor het vragen van feedback.<br /><br />
                    <b>Plan (Hoe ga je de geleerde inzichten toepassen in de toekomst?)</b><br /><br />
                    Ten eerste ga ik in de toekomst op het begin van een project vaste feedbackmomenten inplannen. Ik heb tijdens dit project gemerkt dat dit heel goed werkt om de projectvoortgang te bewaken. Daarnaast ga ik bij taken die afhankelijk zijn van anderen een termijn instellen van een week. Als anderen binnen een week niet hebben gereageerd op mijn verzoek, dan zal ik de vraag herhalen. Na deze week zal ik twee keer per week navraag doen naar de voortgang van deze taak, als de collega nog niet met de taak bezig is geweest. Is deze taak in ontwikkeling, dan zal ik wekelijks een update vragen, om de voortgang van het project te bewaken. Als laatste wil ik in de planning meer bufferweken plannen, zodat er minder kans is dat er taken van de planning geschrapt moeten worden of uitbesteed worden aan collega's. Zo worden ten minste alle geplande taken van het project op de juiste manier uitgevoerd.<br /><br />

                    <h2 className="researchSubtitle" style={{cursor:"auto"}} id="proof">Bewijs van de leeruitkomsten</h2>
                    
                    <br /><br />

                    <table>
                        <tr>
                            <th>Leeruitkomst</th>
                            <th>Bewijs</th>
                        </tr>

                        <tr>
                            <td>
                            Professional duties  
                            </td>
                            <td>
                            Als eerste was <HashLink to="/projectplan#top">onderzocht waar het probleem zat en hoe dit opgelost kon worden.</HashLink> In het projectplan stond de design challenge opgedeeld in vijf onderzoeksvragen. Als deze vragen beantwoord werden, zou de design challenge voltooid kunnen worden. Daarom waren deze onderzoeksvragen beantwoord in <HashLink to="/#projects">gestructureerde onderzoeksverslagen.</HashLink> <br /><br />

                            In de Designfase was <HashLink to="/prototyping#top">een high-fidelity prototype gemaakt in Figma.</HashLink> Dit prototype zou de basis gaan worden voor de doorontwikkeling van Forcasts editor. Het ontwerp was gebaseerd op de eisen en wensen uit de voorgaande onderzoeken.<br /><br />
                            
                            Tevens waren de designs op verschillende manieren getest. Als eerste waren de low-fidelity wireframes getest, door middel van A/B/X-testing. Daarna waren de wireframes uitgewerkt tot het high-fidelity prototype. Hiermee waren vervolgens usability tests uitgevoerd. De voorbereiding van deze tests was opgeschreven in <HashLink to="/testplan#top">het testplan.</HashLink> Daarnaast waren de resultaten gedocumenteerd in <HashLink to="/testverslag#top">het testverslag.</HashLink><br /><br />

                            Als laatste was het project geëvalueerd, om de verbeterpunten in het proces te vinden. Dit stond geschreven in de <HashLink to="/projectproces#top">procesdocumentatie.</HashLink> Hiernaast was een <HashLink to="/adviesverslag#top">adviesverslag en adviespresentatie</HashLink> gemaakt, om Encore een duidelijke toekomstvisie te geven voor de doorontwikkeling van Forcast.


                            </td>
                        </tr>

                        <tr>
                            <td>
                            Situation-Orientation
                            </td>
                            <td>
                            <HashLink to="/#projects">Alle onderzoeksverslagen</HashLink> zouden Encore helpen bij toekomstige projecten die veel onderzoek bevatten. In deze documenten was de werkwijze van dit project te vinden. Met name de <HashLink to="/projectproces#top">procesdocumentatie</HashLink> zal Encore in de toekomst veel aan hebben. Hieruit zou Encore inspiratie kunnen halen voor toekomstige onderzoeken. <br /><br />

                            Verder had Encore de <HashLink to="/design-challenge#top">resultaten van het onderzoek</HashLink>, <HashLink to="/adviesverslag#top">het adviesverslag</HashLink>, <HashLink to="/prototyping#top">de designs</HashLink> en <HashLink to="/testverslag#top">het testverslag</HashLink> mogen ontvangen. Hiermee zou Encore de doorontwikkeling van Forcast volledig door kunnen zetten, wanneer deze stageperiode het einde bereikt. 


                            </td>
                        </tr>

                        <tr>
                            <td>
                            Future-oriented organisation 
                            </td>
                            <td>
                            In <HashLink to="/projectplan#top">het projectplan</HashLink> was een stakeholder analyse gedaan. Hierin stond wie de stakeholders waren en hoe nauw het contact met hen moest zijn. In dit projectplan stond ook beschreven welke taken uitgevoerd moesten worden. Deze taken werden bijgehouden in <HashLink to="/logboek#top">het logboek</HashLink> en via een interactieve planning in Trello. Daarnaast werd uit het projectplan duidelijk door welke fases het project loopte en welke deliverables bij deze fases hoorden. Als laatste stonden de onderzoeksvragen in het projectplan, die beantwoord moesten worden om het probleem op te lossen. Deze onderzoeksvragen hadden ervoor gezorgd dat niet van de rode draad van het project werd afgedwaald.<br /><br />

                            Naast het projectplan was goed gekeken naar een lange-termijn toekomstvisie. Encore wilde namelijk niet dat het probleem zich kon herhalen. In <HashLink to="/adviesverslag#top">het adviesverslag</HashLink> werd beschreven hoe deze langetermijnvisie eruit zag.

                            </td>
                        </tr>

                        <tr>
                            <td>
                            Investigative problem solving
                            </td>
                            <td>
                            In <HashLink to="/projectplan#top">het projectplan</HashLink> was het onderzoek volledig voorbereid. Ten eerste waren alle domeinen van het DOT-framework gebruikt. Dit was gedaan om het probleem van alle kanten te onderzoeken. Zo kon de beste oplossing bedacht worden. Daarnaast zijn de methodes van het CMD-methods pack gebruikt. Deze onderzoeksmethodes pasten bij de onderzoeksvragen. De onderzoeksmethodes hadden ervoor gezorgd dat de onderzoeksvragen op de juiste manier beantwoord konden worden. <br /><br />

                            Op basis van <HashLink to="/enquete#top">het onderzoek naar de wensen van klanten</HashLink> en <HashLink to="/benchmark#top">het benchmarkonderzoek</HashLink> was een oplossing bedacht. Die oplossing was vervolgens <HashLink to="/testverslag#top">getest om verificatie te krijgen.</HashLink> Hierdoor kon met zekerheid gezegd worden dat het probleem op de juiste manier werd aangepakt.<br /><br />

                            Als laatste was het antwoord op de hoofdvraag concreet geformuleerd in <HashLink to="/design-challenge#top">de conclusie.</HashLink> Deze conclusie, samen met het bijbehorende <HashLink to="/prototyping#top">prototype</HashLink>, vormen het definitieve antwoord op de design challenge. 
                            </td>
                        </tr>

                        <tr>
                            <td>
                            Personal Leadership  
                            </td>
                            <td>
                            Ik wilde een frontend webdeveloper in een klein team worden. Dit had ik laten zien in de aanpak van dit project. Ik had leiderschap gepakt en de planning aangepast waar nodig. Daarnaast had ik actief contact gehouden met stakeholders voor feedback. Deze feedback had ik gedocumenteerd in <HashLink to="/logboek#top">het logboek.</HashLink> Daarnaast <HashLink to="/reading-guide#reflection">had ik gereflecteerd op het proces van deze stage.</HashLink> Dit was gedaan om verbeterpunten te vinden, zodat deze in de toekomst voorkomen kunnen worden.<br /><br />

                            Daarnaast hadden elke week twee feedbackgesprekken met mijn begeleider plaatsgevonden. Dit werd pas opgestart in de laatste helft van deze stage. Het doel hiervan was om mijn begeleider up-to-date te houden van mijn werk en feedback te krijgen over hoe ik verder kon met het project. Deze feedback staat ook gedocumenteerd in <HashLink to="/logboek#top">het logboek.</HashLink> 

                            </td>
                        </tr>

                        <tr>
                            <td>
                            Targeted Interaction 
                            </td>
                            <td>
                            Aan het begin van het project was er veel tijd gestoken in het vaststellen van de stakeholders. Dit was gedocumenteerd in <HashLink to="/projectplan#top">het projectplan.</HashLink> Vanuit de stakeholder matrix werd duidelijk welke stakeholders belangrijke rollen speelden in dit project. Door deze rollen in beeld te hebben, werd het duidelijk welke stakeholders bij welke meetings moesten zijn. Zo kon het meest efficiënt feedback ontvangen worden. <br /><br />
                            
                            In de meetings en in de feedbackgesprekken was er tevens altijd doelbewust gecommuniceerd. Dit was gedaan om zo efficiënt mogelijk te communiceren. Goede voorbeelden hiervan waren <HashLink to="/enquete#top">de gesprekken rondom wensen van klanten.</HashLink> Daarnaast heeft <HashLink to="/testplan#top">de voorbereiding van het testen</HashLink> er voor gezorgd dat de tests efficiënt en doelbewust werd uitgevoerd.

                            </td>
                        </tr>
                    </table>

                    <br /><br />

                    <h2 className="researchSubtitle" style={{cursor:"auto"}} id="references">Bronnenlijst</h2>

                    <br />

                    <ul>
                        <li>Bond, L. (2023, May 15). Writing an academic reflection. Learning Lab. Geraadpleegd op 14 juni 2024, van <Link to="https://emedia.rmit.edu.au/learninglab/content/writing-academic-reflection">https://emedia.rmit.edu.au/learninglab/content/writing-academic-reflection</Link> </li>
                    </ul>
                    <br />
                    </p>
                </div>
                </div>
                </div>
                <Footer />
        </React.StrictMode>
    )
}

export default ReadingGuide;