import React from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import AOS from 'aos';
import testPhoto from './photos/testfoto.jpg';
import testplan from "./documents/Testplan.pdf";


import 'aos/dist/aos.css'; 

function Testverslag() {

    AOS.init();

    return (
        <React.StrictMode>
        <Navbar />
        <div class="bodyContent">
        <div style={{ backgroundImage: `url(${testPhoto})`}} class="coverImage benchmarkImg noTranslate testverslagImg" id="projects">
                    <div class="project">
                        <div class="projectTitleDesc">
                            <h2 class="projectTitle">Testverslag</h2>
                                <em class="subtitle">Onderzoeksverslag</em>
                                <br />
                                <a href={testplan} style={{display: "inline-block"}}>
                                <input type="button" value="Testverslag" class="viewBtn" />
                                </a>
                        </div>
                        <div class="projectDesc">
                        </div>
                    </div>
                </div>
                <div class="explanation">
                <div data-aos="fade-up">
                    <h1 class="researchTitle" id="research">Resultaten</h1>
                    <h2 class="researchSubtitle" id="concurrenten">Testverslag</h2>
                    <div data-aos="fade-up">
                    <div class="tag researchTag leftTag">A/B/X-testing</div>
                    <div class="tag researchTag">Usability testing</div>
                    <p class="researchText"><img src={testPhoto} alt="Picture of testing"  className="sidePicture"/><b>Waarom? -</b> Het testverslag is gemaakt om alle resultaten en het proces van de 
                    tests te documenteren. Vanuit de resultaten worden de vervolgstappen vastgesteld in het project. De documentatie van het proces kan ook gebruikt worden om later soortgelijke 
                    tests uit te voeren. Tevens beantwoordt dit de volgende onderzoeksvraag: <b>"Hoe kan er validatie gekregen worden op de uitbreiding van Forcast, zodat gebruikers van Forcast minder snel wensen naar nieuwe uitbreidingen van Forcast?"</b>
                    <br /><br /><b>Hoe? -</b> Door middel van A/B/X-testing en Usability testing is er tot de resultaten gekomen.
                    <br /><br />
                    <b>Resultaten -</b> Uit de tests zijn een aantal dingen als verbeterpunten aangekaart door de testers. De belangrijkste punten waren dat het configureren van animaties lastig was, 
                    dat de elementpermissies uitgebreider moesten, dat extra CMS-koppelingen maatwerk zouden worden en dat iedereen sjablonen moet kunnen aanmaken, met een controle van Encore als 
                    tussenlaag. 
                    <br /><br />
                    <b>Conclusie -</b> Er moest een flink aantal dingen veranderen aan het prototype, voordat deze gerealiseerd kon worden. Met name de extra CMS-koppelingen waren maatwerk. Elke klant 
                    had namelijk eigen koppelingen die gemaakt moesten worden. CMS-widgets pakten dus niet het grote probleem aan. Het menu wat voormalig CMS-widgets was, werd nu een menu voor 
                    stickers en GIFS. Met deze stickers en GIFS konden gebruikers hun slides extra stylen. Daarnaast moest er een mogelijkheid komen voor reguliere gebruikers om ook sjablonen aan te maken. Ze mochten 
                    voor henzelf sjablonen maken zonder moderatie van Encore. Pas wanneer gebruikers een sjabloon wilden maken voor universeel gebruik, 
                    zou Encore deze dan moeten keuren. Een goedgekeurd universeel sjabloon kwam namelijk in de bibliotheek van Encore te staan. Gebruikers kregen zo volledige designvrijheid, als ze voor henzelf werkten.
                    </p>
                    <p>
                    <p class="researchText">Hieronder is het volledige testverslag te lezen.</p>
                    <a href={testplan}>
                        <input type="button" value="Testverslag" class="viewBtn"/>
                    </a>
                    </p>
                    </div>
                    </div>
                </div>
                </div>
                <Footer />
        </React.StrictMode>
    )
}

export default Testverslag;