import React from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import AOS from 'aos';
import designPatternSearch from "./photos/Design pattern search.png";
import researchDocBenchmark from "./documents/Benchmarking research.pdf";
import benchmarkImage from "./photos/benchmark.jpg";

import 'aos/dist/aos.css'; 

function Benchmark() {

    AOS.init();

    return (
        <React.StrictMode>
        <Navbar />
        <div class="bodyContent">
        <div style={{ backgroundImage: `url(${benchmarkImage})`}} class="coverImage benchmarkImg noTranslate benchmarkResearch" id="projects">
                    <div class="project">
                        <div class="projectTitleDesc">
                            <h2 class="projectTitle">Benchmarkonderzoek</h2>
                                <em class="subtitle">Onderzoeksverslag</em>
                                <br />
                                <a href={researchDocBenchmark} style={{display: "inline-block"}}>
                                <input type="button" value="Onderzoeksverslag" class="viewBtn" />
                                </a>
                        </div>
                        <div class="projectDesc">
                            
                        </div>
                    </div>
                </div>
                <div class="explanation">
                <div data-aos="fade-up">
                    <h1 class="researchTitle" id="research">Resultaten</h1>
                    <h2 class="researchSubtitle" id="concurrenten">Benchmarkonderzoek</h2>
                    <div data-aos="fade-up">
                    <div class="tag researchTag leftTag">Benchmark creation</div>
                    <div class="tag researchTag">Design Pattern Search</div>
                    <p class="researchText"><img src={designPatternSearch} alt="Design pattern search table" className="sidePicture"/><b>Waarom? -</b> Het benchmarkonderzoek is gedaan om erachter te komen 
                    aan welke eisen Forcast moet voldoen om te concurreren met andere bedrijven. Het zorgt ook voor nieuwe inzichten in verbeteringen voor Forcast. Andere systemen hebben deze functies namelijk voor een reden. Dit zal 
                    de volgende onderzoeksvraag beantwoorden: <b>"Welke functies en mogelijkheden hebben andere narrowcastingsystemen die kunnen passen in de uitbreiding van Forcast?"</b>
                    <br /><br /><b>Hoe? -</b> Door benchmark creation en een design pattern search is er tot deze resultaten gekomen.
                    <br /><br />
                    <b>Resultaten -</b> Uit de benchmark creation is inspiratie opgedaan. Ten eerste viel op hoeveel narrowcastingsystemen een uitgebreide editor hadden. Sommige systemen hadden de editor zelfs 
                    gekoppeld aan het CMS. Ook viel op dat veel concurrenten werken met templates. Naast deze templates hadden dezelfde concurrenten ook vaak een vaste bibliotheek met afbeeldingen en andere media. 
                    Dit zorgt ervoor dat gebruikers niet veel content moeten uploaden, voordat ze aan de slag kunnen met de software. Verder bleek nog dat sommige systemen gebruik maakten van real-time collaboration. <br /><br />

                    De design pattern search liet zien dat alle geteste benchmarks een vorm van een slide editor hebben. Sommige van deze editors werken met templates, terwijl anderen volledig vrij in gebruik zijn. Daarnaast bleek het 
                    dat de meeste bedrijven die naast narrowcasting nog meer services aanboden, hun editor ook gekoppeld hadden aan het CMS. Dit is om ervoor te zorgen dat de gebruiker alle zijn content makkelijk kan gebruiken in de 
                    editor, zonder alles apart te moeten uploaden. Ook zorgt het voor dynamische slides, door widgets to combineren met CMS inhoud. Het viel op dat Forcast deze belangrijke zaken nog niet geïmplementeerd had.
                    <br /><br />
                    <b>Conclusie -</b> Forcast kan heel goed gebruik maken van een editor die werkt met templates. Op basis van templates kunnen gebruikers namelijk makkelijk slides 
                    ontwerpen die er professioneel en logisch uitzien. Het idee vanuit de benchmarks is om deze editor ook te koppelen aan het CMS. Dit is om ervoor te zorgen klanten van Encore de inhoud van hun websites, applicaties en andere producten 
                    meteen kunnen gebruiken in de editor van Forcast. Dit zal gebruikers veel tijd en moeite besparen met het uploaden van conten. Toch blijft de belangrijkste ontwikkeling de uitgebreidere editor, op basis van templates.
                    Dit komt omdat de benchmarks aangeven dat een uitgebreide editor een must is in een narrowcastingsysteem. 
                    </p>
                    <p>
                    <p class="researchText">Hieronder is het volledige onderzoeksverslag te lezen.</p>
                    <a href={researchDocBenchmark}>
                        <input type="button" value="Onderzoeksdocument" class="viewBtn"/>
                    </a>
                    </p>
                    </div>
                    </div>
                </div>
                </div>
                <Footer />
        </React.StrictMode>
    )
}

export default Benchmark;