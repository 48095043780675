import Home from './home.jsx';
import { HashRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Benchmark from './benchmarking.jsx';
import Survey from './enquete.jsx';
import Projectplan from './projectplan.jsx';
import Testplan from './testplan.jsx';
import Testverslag from './testverslag.jsx';
import ReadingGuide from './readingGuide.jsx';
import Logboek from './logboek.jsx';
import Prototyping from './prototyping.jsx';
import Projectproces from './projectproces.jsx';
import Adviesverslag from './adviesverslag.jsx';
import Conclusie from './conclusie.jsx';

function App() {
  return (
    <HashRouter>
     <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/benchmark" element={<Benchmark />} />
        <Route path="/enquete" element={<Survey />} />
        <Route path="/projectplan" element={<Projectplan/>}/>
        <Route path="/testplan" element={<Testplan/>}/>
        <Route path="/testverslag" element={<Testverslag/>}/>
        <Route path="/reading-guide" element={<ReadingGuide/>}/>
        <Route path="/logboek" element={<Logboek />}/>
        <Route path="/projectproces" element={<Projectproces />}/>
        <Route path="/prototyping" element={<Prototyping />}/>
        <Route path="/adviesverslag" element={<Adviesverslag />}/>
        <Route path="/design-challenge" element={<Conclusie />}/>
      </Routes>
    </HashRouter>
  );
}

export default App;
