import React from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import AOS from 'aos';
import persona1 from "./photos/persona1.png";
import userResearch from "./documents/User research.pdf";
import userImage from "./photos/logboek.png";
import logboek from "./documents/Logboek S8 .pdf";

import 'aos/dist/aos.css'; 

function Logboek() {

    AOS.init();

    return (
        <React.StrictMode>
        <Navbar />
        <div class="bodyContent">
        <div class="coverImage noTranslate logboek" style={{ backgroundImage: `url(${userImage})`}} id="projects">
                    <div class="project">
                        <div class="projectTitleDesc">
                            <h2 class="projectTitle">Logboek</h2>
                            <em class="subtitle">Projectdocumentatie</em>
                            <br />
                            <a href={logboek}>
                            <input type="button" value="Logboek" class="viewBtn" />
                            </a>
                        </div>
                        <div class="projectDesc">
                        </div>
                    </div>
                </div>
                <div class="explanation">
                <div data-aos="fade-up">
                    <h1 class="researchTitle" id="research">Resultaten</h1>
                    <h2 class="researchSubtitle" id="concurrenten">Logboek</h2>
                    <div data-aos="fade-up">
                    <p class="researchText"><b>Waarom? -</b><img src={userImage} alt="Forcasts current editor"  className="sidePicture"/> Het logboek is gemaakt als geheugensteun van het project. In dit logboek staan alle feedback gedocumenteerd. 
                    Ook is hierin te vinden wat per week gedaan is voor het project. Het logboek kan dus altijd geraadpleegd worden als geheugensteun. 

                    <br /><br /><b>Hoe? -</b> Op basis van alles wat tijdens deze opdracht is gedaan, is het logboek geschreven.
                    <br /><br />
                    <b>Resultaten -</b> Het resultaat is een logboek geworden, waarin de gebeurtenissen van elke week zijn gedocumenteerd. Dit logboek bevat alle feedback en alle acties van deze hele opdracht. 
                    Vanaf de eerste week tot de laatste week van deze stage zijn de gebeurtenissen gedocumenteerd. Verder staat ook gedocumenteerd wat de plannen zijn voor elke opvolgende week.
                    <br /><br />
                    <b>Conclusie -</b> Dit logboek was een goede geheugensteun tijdens het project. Meerdere malen was gerefereerd naar het logboek om feedback terug te vinden. Ook was het logboek gebruikt om 
                    te kijken hoe wat er in voorgaande weken gedaan was. Verder kon in het logboek ook gevonden worden wat de planning was voor opvolgende weken. 
                    </p>
                    <p class="researchText">Hieronder is het volledige onderzoeksverslag te lezen.</p>
                    <a href={logboek}>
                        <input type="button" value="Logboek" class="viewBtn" />
                    </a>
                    </div>
                    </div>
                    </div>
                    <br /><br />
                </div>
                <Footer />
                </React.StrictMode>
    )
}

export default Logboek;