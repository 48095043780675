import React from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import AOS from 'aos';
import researchDocBenchmark from "./documents/Projectdocumentatie - proces.pdf";
import benchmarkImage from "./photos/planning week 16.png";

import 'aos/dist/aos.css'; 

function Projectproces() {

    AOS.init();

    return (
        <React.StrictMode>
        <Navbar />
        <div class="bodyContent">
        <div style={{ backgroundImage: `url(${benchmarkImage})`}} class="coverImage benchmarkImg noTranslate planning" id="projects">
                    <div class="project">
                        <div class="projectTitleDesc">
                            <h2 class="projectTitle">Projectproces</h2>
                                <em class="subtitle">Onderzoeksverslag</em>
                                <br />
                                <a href={researchDocBenchmark} style={{display: "inline-block"}}>
                                <input type="button" value="Documentatie projectproces" class="viewBtn" />
                                </a>
                        </div>
                        <div class="projectDesc">
                            
                        </div>
                    </div>
                </div>
                <div class="explanation">
                <div data-aos="fade-up">
                    <h1 class="researchTitle" id="research">Resultaten</h1>
                    <h2 class="researchSubtitle" id="concurrenten">Projectdocumentatie</h2>
                    <div data-aos="fade-up">
                    <div class="tag researchTag leftTag">Literature research</div>
                    <p class="researchText"><b>Waarom? -</b><img src={benchmarkImage} alt="Forcasts current editor"  className="sidePicture"/> Deze projectdocumentatie is geschreven kort en gestructureerd 
                    het proces gedocumenteerd te hebben. Ook is er gereflecteerd op het proces. Op deze manier zijn verbeterpunten gevonden voor het proces. Encore kan deze documentatie opvolgen bij toekomstige projecten, 
                    om zo efficiënt mogelijk soortgelijke projecten te maken. Dit zal tevens de volgende onderzoeksvraag beantwoorden: <b>“Hoe kan een uitbreiding van Forcast gerealiseerd worden, waarmee gebruikers van Forcast de vrijheid hebben om de aanpassingen te maken die ze willen?”</b>
                    <br /><br /><b>Hoe? -</b> Met behulp van een Literature Study is er tot deze resultaten gekomen. Daarnaast is het proces tijdens het project gedocumenteerd en is op dit proces gereflecteerd.
                    <br /><br />
                    <b>Resultaten -</b> Het proces is gestart met een projectplan. In dit projectplan is het probleem geïdentificeerd en een mogelijk oplossing bedacht. Omdat het probleem nog niet helemaal duidelijk is geworden, 
                    zijn een benchmarkonderzoek en een onderzoek naar de wensen van klanten gedaan. De uitgebreide resultaten hiervan zijn te vinden op de respectievelijke pagina's van deze producten. Op basis van deze resultaten is 
                    een klein onderzoek uitgevoerd naar een Canva-koppeling voor Forcast. Dit is nog niet mogelijk, gezien de API van Canva nog niet beschikbaar is voor externe applicaties. Vervolgens zijn low-fidelity wireframes gemaakt en getest met A/B/X-testing. 
                    Hier is één wireframe het beste uit de test gekomen. Dit wireframe is de basis geworden van het high-fidelity prototype. Deze is getest met Usability Testing. De resultaten van deze tests 
                    zijn grotendeels verwerkt in het prototype. De overige punten en adviezen zijn gedocumenteerd in het adviesverslag. <br /><br />
                    Als verbeterpunten zijn een aantal dingen vastgesteld. Ten eerste moet in de toekomst de enquête efficiënter. Ook is het beter als deze enquête naar meer gebruikers gestuurd kan worden. 
                    Daarnaast moet er vaker naar feedback worden gevraagd. Zo blijven de stakeholders gedurende het gehele proces op de hoogte van de voortgang. Ook zorgt dit ervoor dat het project constant voorzien 
                    is van feedback. Met deze feedback blijft het project constant in de juiste richting bijgesteld worden. Als laatste verbeterpunt moeten in de toekomst de tests daadwerkelijk met gebruikers uitgevoerd worden.
                    Hierdoor is de data uit de tests het meest accuraat en kan door gebruikers geverifiëerd dat het probleem wordt aangepakt.
                    <br /><br />
                    <b>Conclusie -</b> In het kort was het aangeraden dat Encore in de toekomst soortgelijke projecten op dezelfde manier aanpakt. Het proces zou in de toekomst op dezelfde manier uitgevoerd kunnen worden, mits aandacht wordt besteed de verbeterpunten. 
                    Als de verbeterpunten opgevolgd zouden worden, dan zouden toekomstige projecten zo efficiënt mogelijk lopen. Voornamelijk was het belangrijk voor het proces om regelmatig de stakeholders in te lichten over voortgang. Ook
                    was het heel belangrijk meer samen te werken met gebruikers voor de tests en de enquête. Zo zou in de toekomst het probleem beter geanalyseerd kunnen worden. Hierdoor zou de oplossing 
                    meer aan kunnen sluiten bij wat de gebruikers wensten.
                    </p>
                    <p>
                    <p class="researchText">Hieronder is het volledige onderzoeksverslag te lezen.</p>
                    <a href={researchDocBenchmark}>
                        <input type="button" value="Documentatie projectproces" class="viewBtn"/>
                    </a>
                    </p>
                    </div>
                    </div>
                </div>
                </div>
                <Footer />
        </React.StrictMode>
    )
}

export default Projectproces;