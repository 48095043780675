import React, { useState } from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import projPlan from "./documents/Projectplan.pdf";
import obsEnInt from "./documents/Startobservaties en interviews.pdf";
import AOS from "aos";
import projectplan from "./photos/currentForcast.png";

function Projectplan() {

    AOS.init();

    return(
        <>
            <Navbar />
                <div className="bodyContent">
                <div class="coverImage noTranslate logboek projectplan" id="projects">
                    <div class="project">
                        <div class="projectTitleDesc">
                            <h2 class="projectTitle">Projectplan</h2>
                                <em class="subtitle">Projectvoorbereiding</em>
                                <br />
                                <a href={obsEnInt}>
                                <input type="button" value="Observaties en interviews" class="viewBtn" />
                                </a>
                                <a href={projPlan}>
                                <input type="button" value="Projectplan" class="viewBtn" style={{marginTop: "20px"}}/>
                                </a>
                        </div>
                        <div class="projectDesc">
                        </div>
                    </div>
                </div>
                <div class="explanation">
                <div data-aos="fade-up">
                    <h1 class="researchTitle">Projectplan</h1>
                    <h2 class="researchSubtitle" id="prepWeek">Begrijpen van het probleem en het bedenken van een oplossing</h2>
                    <div data-aos="fade-up">
                    <div class="tag researchTag leftTag">Interview</div>
                    <div class="tag researchTag">Observation</div>
                    <p class="researchText">
                    <b>WAAROM? -</b> <img src={projectplan} alt="Forcasts current editor"  className="sidePicture"/>Met een projectplan wordt het probleem concreet. Hierdoor is het moeilijk om tijdens het project van de rode draad af te dwalen. Het projectplan gaat tevens het leidende 
                    document worden voor het project. Gedurende het project kan het projectplan gebruikt worden als geheugensteun.
                    <br /><br /><b>HOE? -</b> Door middel van interviews en observaties is het probleem geïdentificeerd. Het projectplan is geschreven op basis van dit probleem.
                    <br /><br /><b>Resultaten -</b> Uit de interviews met Koen Hendrix en Wouter Janssen is gebleken dat Encore al langer Forcast wil verbeteren. Echter werd er
                    gezegd dat Encore hier niet de tijd voor kon vinden. Andere prioriteiten lagen namelijk hoger. Uit het interview met Luuk Wilms kwam dat Forcast een aantal belangrijke onderdelen mist, zoals sjablonen en meer functionaliteit in de editor. Deze statement resoneerde met de opinies van Koen en Wouter. Ook vertelde Luuk dat er 
                    een klant was gaan lopen doordat Forcast niet snel genoeg was doorontwikkeld. Daarnaast zei Luuk dat ik het beste kon spreken met Jesse Peeters, gezien hij het vaakste Forcast installeert 
                    bij klanten. <br /><br />
                    Verder viel tijdens de eerste weken van het project op dat er vaak gezegd werd dat tijd de reden was dat Forcast niet doorontwikkeld kon worden. Uit observaties was te dat Encore inderdaad heel druk bezig 
                    is met alle andere projecten die hogere prioriteit hebben. Maar wat duidelijk werd, was dat Encore niet precies op kon noemen welke suggesties klanten voor Forcast hadden.
                    De lijst met issues in Gitlab bevestigde nogmaals dat de editor een belangrijk pijnpunt was. Als laatste is er kritisch naar Forcast zelf gekeken, waaruit bleek dat de editor inderdaad 
                    fundamentele functies miste. <br /><br />
                    Door deze onderzoeken werd het probleem concreet. Het was duidelijk wat voor oplossing er hoogstwaarschijnlijk ging komen. Hierdoor werd het mogelijk om een 
                    design challenge op te stellen. Ook was nu duidelijk wat gedaan moest worden om de design challenge te voltooien. Op basis van deze resultaten is het projectplan geschreven.
                    <br /><br /><b>Conclusie -</b> Wat meteen duidelijk werd, was dat de editor van Forcast een probleem was. Deze moest doorontwikkeld worden. Stakeholders gaven al een aantal suggesties, 
                    zoals het introduceren van sjablonen en het geven van meer rechten aan resellers. Echter viel het op dat het nog niet precies duidelijk was welke wensen gebruikers hadden. Daarom is er 
                    besloten om een vragenlijst op te stellen voor gebruikers. Deze context heeft geleid tot het projectplan en de design challenge van deze opdracht: <br /><br />
                    <em style={{color: "black"}}><b>"Ontwerp een uitbreiding van Forcast, die ervoor zorgt dat gebruikers van Forcast, wanneer ze iets willen aanpassen in Forcast, 
                    de vrijheid hebben om de aanpassingen te maken die ze willen, zodat gebruikers van Forcast minder snel wensen naar nieuwe uitbreidingen van Forcast."</b></em>
                    </p>
                    <p class="researchText">Hieronder is onderzoeksverslag van de observaties en de interviews en het volledige projectplan te lezen.</p>
                    <a href={obsEnInt} className="linkBtn">
                        <input type="button" value="Observaties en interviews" class="viewBtn"/>
                    </a>
                    <a href={projPlan} className="linkBtn" style={{marginLeft: "20px"}}>
                        <input type="button" value="Projectplan" class="viewBtn"/>
                    </a>
                    </div>
                    </div>
                    </div>
                    <br /><br />
                    </div>
                <Footer />
        </>
    )
}

export default Projectplan;