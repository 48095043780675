import React from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import AOS from 'aos';
import prototypeOverview from './photos/prototype overzicht.png';
import testplan from "./documents/Testplan.pdf";


import 'aos/dist/aos.css'; 

function Prototyping() {

    AOS.init();

    return (
        <React.StrictMode>
        <Navbar />
        <div class="bodyContent">
        <div style={{ backgroundImage: `url(${prototypeOverview})`}} class="coverImage benchmarkImg noTranslate prototypeImg" id="projects">
                    <div class="project">
                        <div class="projectTitleDesc">
                            <h2 class="projectTitle">Prototype nieuwe editor Forcast</h2>
                                <em class="subtitle">Design</em>
                                <br />
                                <a href="https://www.figma.com/design/WcF5GTHOURWzPngkTW5LJd/Forcast-Wireframe-A?t=hM1pKiYHTwEnketx-1" style={{display: "inline-block"}}>
                                <input type="button" value="Figma prototype" class="viewBtn" />
                                </a>
                        </div>
                        <div class="projectDesc">
                        </div>
                    </div>
                </div>
                <div class="explanation">
                <div data-aos="fade-up">
                    <h1 class="researchTitle" id="research">Resultaten</h1>
                    <h2 class="researchSubtitle" id="concurrenten">Het ontwikkelen van de nieuwe Forcast editor</h2>
                    <div data-aos="fade-up">
                    <div class="tag researchTag leftTag">Prototyping</div>
                    <p class="researchText"><img src={prototypeOverview} alt="Figma Prototype" className="sidePicture"/><b>Waarom? -</b> Met een prototype kan duidelijk gecommuniceerd worden hoe het design van 
                    de oplossing eruit komt te zien. Tevens kan er met een prototype worden getest, om verbeterpunten te vinden. Daarnaast kost het maken van een prototype minder tijd dat het volledig programmeren van 
                    het design. Het prototype zal ook het leidende design zijn voor de Realisatiefase. 
                    <br /><br /><b>Hoe? -</b> Door middel van prototyping is er tot de resultaten gekomen.
                    <br /><br />
                    <b>Resultaten -</b> Als resultaat is een volwaardig interactief prototype van de nieuwe editor van Forcast ontwikkeld. De ontwikkeling hiervan is gestart met een low-fidelity wireframe. 
                    Deze is doorontwikkeld met kleur, afbeeldingen en interactie tot een high-fidelity prototype. Dit high-fidelity prototype is daarnaast doorontwikkeld op basis van de resultaten van Usability Tests. Met de feedback 
                    uit deze tests is de user experience beter geworden. Tevens is uit de tests gebleken dat CMS-widgets niet effectief zijn om het probleem aan te pakken. In plaats daarvan zijn stickers en GIFs toegevoegd.
                    <br /><br />
                    <b>Conclusie -</b> Dit prototype was de basis voor de doorontwikkeling van Forcast. De wensen van klanten en de toekomstvisie van Encore werden hierin weerspiegeld. Encore zou in de toekomst naar dit prototype 
                    kunnen refereren, zodat het design en de interactie hetzelfde blijft in het eindproduct. Het prototype was namelijk getest en geverifiëerd. Het was dus duidelijk dat dit prototype de vorm 
                    en functionaliteit had van de juiste oplossing.  
                    </p>
                    <p>
                    <p class="researchText">Hieronder is het prototype te zien.</p>
                    <a href="https://www.figma.com/design/WcF5GTHOURWzPngkTW5LJd/Forcast-Wireframe-A?t=hM1pKiYHTwEnketx-1">
                        <input type="button" value="Figma prototype" class="viewBtn" />
                    </a>
                    </p>
                    </div>
                    </div>
                </div>
                </div>
                <Footer />
        </React.StrictMode>
    )
}

export default Prototyping;