import React, {useEffect} from "react";
import Navbar from "./components/navbar.jsx";
import github from "./photos/github-10-64.png";
import linkedIn from "./photos/linkedin-3-64 (1).png";
import { HashLink as Link } from 'react-router-hash-link';
import encore from "./photos/encore-logo.png";
import Footer from "./components/footer.jsx";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Project from "./components/project.jsx";
import benchmarkImg from "./photos/benchmark.jpg";
import vragenForcast from "./photos/benchmarkImg.png";
import testplan from "./photos/Encore.jpg";
import projectplan from "./photos/currentForcast.png";
import forcast from "./photos/forcast.png";
import testPhoto from './photos/testfoto.jpg';
import prototypeOverview from "./photos/prototype v2 overzicht.png";
import planning from "./photos/planning week 16.png";
import adviesverslag from "./photos/adviesverslagBeter.png";
import designChallenge from "./photos/adviesverslag.png";
import logboek from "./photos/logboek.png";

function Home() {

    AOS.init();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    useEffect(() => {
        document.title = 'Portfolio S8 - Niels Vissers';
      }, []);

      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }

      const scrollWithOffsetProjects = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -60; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return (
        <>
            <Navbar />
            <div class="bodyContent">
                <div class="socials">
                    <Link to="https://github.com/Luuntus">
                    <img src={github} alt="github Link" class="socialIcon"/>
                    </Link>
                    <Link to="https://www.linkedin.com/in/nielsvissers/">
                    <img src={linkedIn} alt="LinkedIn Link" class="socialIcon topMarginSocialIcon"/>
                    </Link>
                </div>
                <section id="landing">
                <div class="leftCenterText">
                    <h1 class="title noBottomMargin">Portfolio afstudeerstage bij</h1>
                    <h1 class="blue title noTopMargin noBottomMargin">Encore Digital Agency</h1>
                    <p class="bigParagraph">Ik ben Niels Vissers, afstudeerstudent User Interaction. In dit portfolio is te zien hoe mijn onderzoek is verlopen tijdens mijn afstudeerstage bij Encore Digital Agency. <br /><br />
                    Om te beginnen kan de reading guide via de knop hieronder gelezen worden. Deze reading guide is een introductie en zal verwijzen naar de producten en bewijsstukken.</p>
                    <Link to="/reading-guide#top" class="anchor">
                        <input type="button" value="Lees de Reading Guide" className="startBtn" style={{marginTop: "20px"}}/>
                    </Link>
                    <div className="underIntro">
                        <hr class="leftLine gradient"/><h2 class="or gradient">OF</h2><hr class="rightLine gradient"/>
                    </div>
                </div>
                <div class="scrollPrompt">
                    <div class="vertLine"></div>
                    <p class="scroll">Scroll verder</p>
                </div>
                <div class="rightPhoto">
                    <img src={encore} alt="Logo Encore Digital Agency" class="me"/>
                </div>
                </section>
                <section id="Forcast">
                    <div className="gradient forcastInfo">
                        <div data-aos="fade-up">
                            <h2 className="forcastTitle">Forcast: De narrowcasting van Encore <img src={forcast} alt="Forcast" className="forcastImg"/></h2>
                            <p className="researchText forcastText">Forcast is het narrowcastingsysteem van Encore Digital Agency. Met Forcast kunnen gebruikers slides maken met 
                            widgets. Deze widgets kunnen bijvoorbeeld het weer, ANWB verkeerssituaties of het nieuws zijn. De slides met deze widgets kunnen ze vervolgens weergeven op een scherm naar keuze. Maar er was een probleem met Forcast. 
                            De wensen en suggesties van klanten bleven maar binnenstromen, terwijl Encore te druk bezig was met andere projecten. Toch is het wel van groot belang dat Forcast doorontwikkeld wordt, 
                            want de wensen van klanten blijven binnenstromen. Als deze wensen niet vervuld worden, schept dit irritatie bij de klanten. In dit portfolio wordt er verteld over het onderzoek naar dit probleem en het 
                            oplossen hiervan. Dit met het doel dat er in de toekomst minder snel wensen van klanten binnenstromen en de klanten weer tevreden zijn met Forcast.
                            </p>
                        </div>
                    </div>
                </section>
                <section id="projects">
                    <Project image={projectplan} projectname="logboek" link="/projectplan" name="Projectplan" project="Projectvoorbereiding"/>    
                    <Project image={benchmarkImg} projectname="benchmarkResearch" link="/benchmark" name="Benchmarkonderzoek" project="Onderzoeksdocument" class="projectComp"/>
                    <Project image={vragenForcast} projectname="enquete" link="/enquete" name="Onderzoek naar wensen van klanten" project="Onderzoeksdocument"/>
                    <Project image={prototypeOverview} projectname="prototypeImg" link="/prototyping" name="Prototype nieuwe editor Forcast" project="Design"/>
                    <Project image={testplan} projectname="testplanImg" link="/testplan" name="Testplan" project="Testplan"/>
                    <Project image={testPhoto} projectname="testverslagImg" link="/testverslag" name="Testverslag" project="Onderzoeksdocument"/>
                    <Project image={planning} projectname="planning" link="/projectproces" name="Projectproces" project="Onderzoeksdocument"/>
                    <Project image={adviesverslag} projectname="adviesverslag" link="/adviesverslag" name="Adviesverslag" project="Toekomstadvies Forcast"/>
                    <Project image={designChallenge} projectname="conclusie" projectClass="lessOpacity" link="/design-challenge" name="Conclusie van de design challenge" project="Conclusie"/>
                    <Project image={logboek} projectname="logboek" link="/logboek" name="Logboek" project="Projectdocumentatie"/>
                </section>
                {/* <div data-aos="fade-up">
                <div class="aboutMe">
                    <h1 class="aboutMeTitle" id="aboutMe">About me</h1>
                    <div class="bigCard">
                    <div data-aos="fade-up">
                        <h2 class="compentences">Onderzoek, ontwerp, development en lesgeven, de dingen die ik doe.</h2>
                        <p class="aboutMeText">I am <b>Niels Vissers</b>. As a <b>media designer</b>, I <b>imagine, design and create </b>what you as a customer want. I work best in <b>small teams </b>
                        and I have a drive to do my work right. My main skills include:</p>
                        <div class="skills">
                            <Skill img={adobeXD} title="Adobe XD"/>
                            <Skill img={conceptThinking} title="Concept Thinking"/>
                            <Skill img={me3} title="Communication"/>
                            <Skill img={design} title="Design"/>
                            <Skill img={personalProject} title="HTML/CSS/JS"/>
                        </div>
                        <p class="aboutMeText"><b>Enthousiasm</b> is what describes me best. I am <b>proud</b> of my work. Of <em class="emphasis">our</em> work. 
                        We will make great things, <b>together!</b> And this enthousiasm makes for a <b>pleasant working experience and a great final product.</b> 
                        I will <b>iterate and improve</b> to deliver you with a product <b>we can all be proud of.</b></p>
                        <em class="aboutMeQuote">"Trial and Error, until you succeed"</em>
                        <p class="aboutMeText"><b>But most importantly,</b> I am a <b>clear communicator.</b> I want to make sure that <b>each and every way of communication </b> 
                        gives <b>no miscommunication.</b> If you want to know more about me, you can refer to me <b>resume </b>below.</p>
                        <a href={Resume}>
                        <input type="button" value="Resume" class="viewBtn aboutMeBtn"/>
                        </a>
                    </div>
                    </div>
                    <div class="aboutMePicture">
                        <img src={me2} alt="me, again :)" class="me2"/>
                    </div>
                </div>
                </div> */}
                <div class="footer" id="contact">
                    <Footer />
                </div>
                
            </div>
        </>
    )
}

export default Home;