import React from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import AOS from 'aos';
import testplan from "./documents/Testplan.pdf";
import benchmarkImage from "./photos/Encore.jpg";

import 'aos/dist/aos.css'; 

function Testplan() {

    AOS.init();

    return (
        <React.StrictMode>
        <Navbar />
        <div class="bodyContent">
        <div style={{ backgroundImage: `url(${benchmarkImage})`, backgroundColor: "#f7f7f7"}} class="coverImage benchmarkImg noTranslate" id="projects">
                    <div class="project">
                        <div class="projectTitleDesc">
                            <h2 class="projectTitle">Testplan</h2>
                                <em class="subtitle">Onderzoeksverslag</em>
                                <br />
                                <a href={testplan} style={{display: "inline-block"}}>
                                <input type="button" value="Testplan" class="viewBtn" />
                                </a>
                        </div>
                        <div class="projectDesc">
                        </div>
                    </div>
                </div>
                <div class="explanation">
                <div data-aos="fade-up">
                    <h1 class="researchTitle" id="research">Resultaten</h1>
                    <h2 class="researchSubtitle" id="concurrenten">Testplan</h2>
                    <div data-aos="fade-up">
                    <div class="tag researchTag leftTag">Literature Study</div>
                    <p class="researchText"><b>WAAROM? -</b><img src={benchmarkImage} alt="Forcasts current editor"  className="sidePicture"/> Het testplan is gemaakt om de tests van het prototype voor te bereiden. Zonder deze voorbereiding zullen de tests veel minder effectief zijn, omdat de juiste resultaten 
                    moeilijker te verkrijgen zijn. <b>Tevens beantwoordt dit de volgende onderzoeksvraag: "Hoe kan een uitbreiding van Forcast, getest worden op een effectieve manier met gebruikers van Forcast, met het doel dat ze minder snel wensen naar nieuwe uitbreidingen van Forcast in de toekomst?"</b>
                    <br /><br /><b>HOE? -</b> Door middel van literature study zijn de structuren van de testplannen bepaald.
                    <br /><br />
                    <b>Resultaten -</b> Wat blijkt uit dit onderzoek, is dat het handig is om de testfase te beginnen met het vaststellen van de doelen van de tests. Door deze doelen concreet te hebben, 
                    wordt het duidelijker welke informatie verworven moet worden uit de tests. Daarnaast was het belangrijk dat bij alle tests omschreven wordt hoelang deze duurt, wat het publiek is, op welke 
                    locatie de tests zijn en hoe het verloop van de tests zal gaan. Met al deze informatie zijn de tests makkelijker te plannen en kan het testplan stap voor stap gevolgd worden. Hierdoor is het 
                    moeilijk om af te dwalen van de rode draad in de tests. <br /><br />

                    Als resultaat van deze informatie zijn dan ook drie testplannen opgesteld voor verschillende fases van het project. Er is een testplan voor A/B/X-testing, Usability testing en 
                    een optionele quality review, mocht hier nog tijd voor zijn. Deze testplannen zullen leidend zijn tijdens de testfase.  
                    <br /><br />
                    <b>Conclusie -</b> De beste manier om de uitbreidingen van Forcast te testen, is door in drie fases te testen. Ten eerste moet de indeling van de nieuwe elementen goedgekeurd 
                    worden. Hiermee kan getest worden of de gebruikers de indeling van de nieuwe elementen fijn in gebruik vinden. Ook kan er zo gekeken worden naar eventuele extra toevoegingen. Daarna 
                    moeten er usability tests uitgevoerd worden met het prototype. Op deze manier kan gekeken worden of de user experience goed is voor de gebruikers en wederom of er nog extra suggesties 
                    zijn. Als laatste moet er nog een quality review gedaan worden, net voordat de uitbreidingen live gaan. Zo kunnen net voor launch de laatste bugs uit het systeem gehaald worden, voor een 
                    zo goed mogelijke launch. 
                    </p>
                    <p>
                    <p class="researchText">Hieronder is het volledige testplan te lezen.</p>
                    <a href={testplan}>
                        <input type="button" value="Testplan" class="viewBtn"/>
                    </a>
                    </p>
                    </div>
                    </div>
                </div>
                </div>
                <Footer />
        </React.StrictMode>
    )
}

export default Testplan;