import React from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import AOS from 'aos';
import persona1 from "./photos/persona1.png";
import userResearch from "./documents/User research.pdf";
import userImage from "./photos/benchmarkImg.png";

import 'aos/dist/aos.css'; 

function Survey() {

    AOS.init();

    return (
        <React.StrictMode>
        <Navbar />
        <div class="bodyContent">
        <div class="enquete coverImage benchmarkImg noTranslate" id="projects">
                    <div class="project">
                        <div class="projectTitleDesc">
                            <h2 class="projectTitle">Onderzoek naar wensen van klanten</h2>
                                <em class="subtitle">Onderzoeksverslag</em>
                                <br />
                                <a href={userResearch}>
                                <input type="button" value="Onderzoeksdocument" class="viewBtn" />
                                </a>
                        </div>
                        <div class="projectDesc">
                        </div>
                    </div>
                </div>
                <div class="explanation">
                <div data-aos="fade-up">
                    <h1 class="researchTitle" id="research">Resultaten</h1>
                    <h2 class="researchSubtitle" id="concurrenten">Onderzoek naar wensen van klanten</h2>
                    <div data-aos="fade-up">
                    <div class="tag researchTag leftTag">Interview</div>
                    <div class="tag researchTag">Persona</div>
                    <div class="tag researchTag">Survey</div>
                    <p class="researchText"><img src={persona1} alt="Design pattern search table" className="sidePicture"/><b>Waarom? -</b> Het onderzoek naar de wensen van klanten is gedaan, omdat nog niet duidelijk is waarnaar de gebruikers 
                    van Forcast wensen. Daarnaast blijkt dat Encore geen concrete data heeft over de wensen van gebruikers. Maar om dit te bevestigen, is dit onderzoek 
                    uitgevoerd. Tevens beantwoordt dit onderzoek de volgende onderzoeksvraag: <b>"Wat zijn de eisen en wensen van de gebruikers van Forcast en hoe zijn hieruit de juiste prioriteiten te stellen, zodat ze de vrijheid hebben om de aanpassingen te maken die ze willen, waardoor ze minder snel wensen naar nieuwe uitbreidingen van Forcast?"</b>

                    <br /><br /><b>Hoe? -</b> Door middel van interviews, persona's en een survey is er tot de resultaten gekomen.
                    <br /><br />
                    <b>Resultaten -</b> De interviews met Luuk Wilms, Jesse Peeters en Koen Hendrix hebben geleid tot een aantal inzichten in de wensen van klanten. Het valt op dat de slide editor meerdere keren 
                    wordt benoemd. Volgens Luuk is er namelijk ook al een keer een klant overgestapt naar een concurrent, doordat de editor van Forcast te weinig functionaliteit heeft. Hij vertelt dat klanten te weinig vrijheid hebben in design. 
                    Als gebruikers een eigen ontwerp willen maken, dan moeten ze dit doen in Photoshop. Dit bestand moeten ze dan uploaden in een afbeeldingswidget. Luuk zegt dat klanten hier niet blij mee waren. <br /><br />
                    Jesse heeft een klein prioriteitenlijstje opgesteld. In dit lijstje staan vier soorten wensen, waarvan Jesse zegt dat hij ze het vaakst gevraagd krijgt. Het vaakst gevraagde punt is dat klanten hun kleuren en hun logo niet aan kunnen passen, met maar liefst 6 meldingen. Uit het laatste gesprek met Koen, wordt nog duidelijk 
                    dat Koen verwacht dat uit een enquête nog meer nieuwe wensen zullen komen. Dit ondanks hij verwacht dat de editor nog steeds het grote probleem zou oplossen. Daarnaast wil Koen niet elke Forcast-klant vragen 
                    voor deze enquête. Daarom komt hij met het plan om een select groepje klanten te vragen voor de enquête.
                    <br /><br />
                    Voor de enquête zijn er ook twee persona's gemaakt. Dit is gedaan om een beeld te vormen van verschillende soorten gebruikers van Forcast. Deze persona's zijn gebaseerd op iemand die Forcast veel gebruikt en iemand 
                    die de software van Forcast bijna dagelijks gebruikt. De personas zijn te vinden <a href="https://www.figma.com/file/vtZkeIYNhbGsqq8IGInBjV/Persona's-Forcast?type=design&node-id=0%3A1&mode=design&t=0wEDUyhXF74rv2wf-1">via 
                    deze link</a> en er is een voorbeeld te vinden op de afbeelding hiernaast.
                    <br /><br />
                    De enquête die naar de Forcastgebruikers is gestuurd, heeft niet heel veel resultaat opgeleverd. Er is wel wat gevraagd naar een uitgebreidere editor en naar een 
                    koppeling voor Canva. Verder blijkt het ook dat sommige widgets niet fijn in gebruik zijn en dat de editor tooltips nodig heeft. Als laaste wordt er gewenst dat de 
                    gebruiker een scherm over meerdere afspeellijsten kan aanpassen, als deze vaker wordt gebruikt. Nu moeten gebruikers namelijk nog elk scherm apart aanpassen, ook al is deze 
                    vaker in gebruik. 
                    <br /><br />
                    <b>Conclusie -</b> De uitgebreidere editor was het belangrijkste wat de Forcastgebruikers wensen. Dit pakte het grote probleem aan van te weinig designvrijheid en 
                    het kunnen maken van mooie designs. Wanneer de editor in development zou zijn, kon het slim zijn om een Canva-koppeling te integreren in Forcast.
                    Hiermee hebben gebruikers al een flinke vooruitgang in het maken van mooie designs, maar het bevat nog niet alle functionaliteiten die de editor gaat hebben. Daarnaast 
                    zijn tooltips voor de editor een belangrijke additie tot Forcast. Zo weten gebruikers precies hoe alle functies werken, ook wanneer ze Forcast niet vaak gebruiken. Zo 
                    komen er minder wensen over kleine aanpassingen, vanwege dat gebruikers niet weten hoe bepaalde functies werken. Dit zijn de belangrijkste wensen van de gebruikers van Forcast.
                    </p>
                    <p class="researchText">Hieronder is het volledige onderzoeksverslag te lezen.</p>
                    <a href={userResearch}>
                        <input type="button" value="Onderzoeksdocument" class="viewBtn" />
                    </a>
                    </div>
                    </div>
                    </div>
                    <br /><br />
                </div>
                <Footer />
                </React.StrictMode>
    )
}

export default Survey;